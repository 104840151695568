import {Component, Input, OnInit} from '@angular/core';
import {BillingDocument} from '../../../models/invoices/billing-document';
import {Operation} from '../../../../operations/models/operation';
import {OperationsService} from '../../../../operations/services/operations.service';

@Component({
    selector: 'o-linked-transfers',
    templateUrl: './o-linked-transfers.component.html',
    styleUrls: ['./o-linked-transfers.component.scss']
})
export class OLinkedTransfersComponent {

    @Input()
    set billingDocument(billingDocument: BillingDocument) {
        if (billingDocument) {
            this.operationService.find(
                0,
                this.lastCardinality,
                null,
                null,
                null,
                'OPERATION_DATE:DESC',
                null,
                null,
                null,
                billingDocument.id
            ).subscribe(operations => {
                this.operations = operations.data;
            });
        }
    }

    operations: Operation[];
    lastCardinality = 5;

    constructor(private readonly operationService: OperationsService) {}
}
