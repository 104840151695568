import {ChangeDetectorRef, Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    allLines,
    BillingDocument,
    BillingDocumentType,
    ExtendedBillingDocument
} from '../../models/invoices/billing-document';
import {ExtendedInvoice, Invoice, InvoiceStatus} from '../../models/invoices/invoice';
import {CustomerAccount} from '../../../customers/models/customer-account';
import {CustomerAccountService} from '../../../customers/services/customer-account.service';
import {CustomerService} from '../../services/customer.service';
import {InvoiceLine} from '../../models/invoices/invoice-line';
import {ExtendedQuote, Quote, QuoteStatus} from '../../models/invoices/quote';
import {QuotesService} from '../../services/quotes.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Attachment} from '../../../attachments/models/attachment';
import {BillingDocumentService} from '../../services/billing-document.service';
import {BoaNotNullPipe} from '../../../../shared/pipes/not-null.pipe';
import {NavigationService} from '../../../common/services/navigation.service';
import {plainToClass, plainToClassFromExist} from 'class-transformer';
import {Credit} from '../../models/invoices/credit';
import {CreditService} from '../../services/credit.service';
import {PaymentStatusEnum} from '../../models/payment';
import {HttpErrorResponse} from '@angular/common/http';
import {AddressType} from '../../../common/models/address/addressType';
import {Address} from '../../../common/models/address/address';
import {InvoiceAddressWithName} from '../../models/invoices/address-invoice';
import {Customer} from '../../models/invoices/customer';
import {FullUser} from '../../../users/models/full-user';
import {ConnectedUserService} from '../../../../app-root/services/connected-user.service';
import {UserService} from '../../../users/services/user.service';
import {interval} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {LineEdit} from '../../models/invoices/line-edit';
import {ChorusProCustomerSettings} from '../../../customers/models/chorus-pro-settings';
import {Observable} from 'rxjs/internal/Observable';
import {InvoiceHttpService} from '../../services/invoice-http.service';
import {InvoiceComputingUtils} from '../../invoice-computing-utils';
import {APopupComponent} from '../../../../shared/components/atoms/a-popup-component/a-popup.component';
import {flatMap} from 'rxjs/internal/operators';

@Component({
    selector: 'boa-invoice-detail',
    templateUrl: './invoice-detail.component.html',
    styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';

    @ViewChild(APopupComponent)
    popup: APopupComponent;

    billingDocument: ExtendedBillingDocument | BillingDocument;
    isInvoice = false;
    isQuote = false;
    isCredit = false;
    displayTokenTransfer = false;
    invoiceStatus = InvoiceStatus;
    pdfLink: string;
    pdfFileName: string;
    customerAccount = CustomerAccount.empty();
    chorusProSettings: ChorusProCustomerSettings = null;
    documentType: BillingDocumentType;

    // Only used when billingDoc is a credit
    creditNotToExceed: number;

    get grossTotal(): number {
        return this.billingDocument.grossAmount.value;
    }

    get netTotal(): number {
        return this.billingDocument.netAmount.value;
    }

    get vatTotal(): number {
        return this.billingDocument.vatAmount.value;
    }
    tokenGrossTotal: number;
    quoteStatus = QuoteStatus;

    // button loading
    createInvoiceDraftLoading = false;
    saveInvoiceDraftLoading = false;
    sendInvoiceDraftLoading = false;
    createQuoteDraftLoading = false;
    saveQuoteDraftLoading = false;
    refuseQuoteLoading = false;
    acceptQuoteLoading = false;
    cancelQuoteLoading: boolean;
    resendToChorusProLoading = false;

    // customers map
    allCustomersCode = [];
    allCustomersName = [];

    // payments (only invoices)
    payments = [];

    // credits
    credits: Credit[] = [];
    linkedDocuments: Attachment[] = [];

    // invalid / pristine for each form component
    customersInvalid: boolean;
    addressesInvalid: boolean;
    generalInfoInvalid: boolean;
    addressesDirty: boolean;
    customersDirty: boolean;
    generalInfoDirty: boolean;
    linesDirty: boolean;
    discountDirty: boolean;

    // token transfer
    connectedUser: FullUser = FullUser.empty();
    customerAccountManager: FullUser = FullUser.empty();

    buttonsList: Array<{
        label: string;
        icon: string;
        action: () => void;
    }> = [];

    private static recomputeLineVatAmount(invoiceLine: InvoiceLine, vatPercentage: number): void {
        const grossAmountAfterDiscount = invoiceLine.grossAmount.value - invoiceLine.discountAmount.value;
        invoiceLine.vatAmount.value = Math.round(grossAmountAfterDiscount * vatPercentage * 100) / 100;
    }

    constructor(private route: ActivatedRoute,
                private customerAccountService: CustomerAccountService,
                private billingDocumentService: BillingDocumentService,
                private changeDetector: ChangeDetectorRef,
                private invoiceService: InvoiceHttpService,
                private boaNotNullPipe: BoaNotNullPipe,
                private navigationService: NavigationService,
                private creditService: CreditService,
                private customerService: CustomerService,
                private quoteService: QuotesService,
                private notificationService: ToastrService,
                private translateService: TranslateService,
                private router: Router,
                private readonly connectedUserService: ConnectedUserService,
                private readonly userService: UserService,
                private readonly toastrService: ToastrService) {
    }

    ngOnInit(): void {
        this.route.data.subscribe((
            data: {billingDocument: ExtendedBillingDocument,
            allCustomersCode: Array<{label: string, value: string}>,
            allCustomersName: Array<{label: string, value: string}>}) => {

            // is a creation (empty billing) or a consultation
            if (data.billingDocument) {
                this.billingDocument = data.billingDocument;
            } else {
                // is a new credit
                if (history.state.credit) {
                    this.billingDocument = plainToClass(Credit, history.state.credit);
                    this.creditNotToExceed = history.state.creditNotToExceed;
                } else if (history.state.duplicate) { // is a duplication
                    // Reset references to compute new reference because two billing documents
                    // cannot have the same reference
                    const duplicatedBillingDocument = history.state.duplicate;
                    duplicatedBillingDocument.reference = null;

                    this.billingDocument = this.invoiceService.instantiateBillingDocument(history.state.duplicate);
                }
            }

            // customers selects
            this.allCustomersCode = data
                .allCustomersCode
                .map(el => {
                    el.label = this.boaNotNullPipe.transform(el.label, 'common.not.provided');
                    return el;
                });
            this.allCustomersName = data
                .allCustomersName
                .map(el => {
                    el.label = this.boaNotNullPipe.transform(el.label, 'common.not.provided');
                    return el;
                });

            if (!this.billingDocument.trustValues) {
                this.calculateNumbersWithoutDiscount();
            }

            // used in templates to avoid function calls
            this.isInvoice = this.billingDocument instanceof Invoice;
            this.isQuote = this.billingDocument instanceof Quote;
            this.isCredit = this.billingDocument instanceof Credit;
            this.documentType = this.billingDocument.billingDocumentType;
            this.pdfLink = this.billingDocument.getPdfLink();
            this.pdfFileName = this.billingDocument.getFileName();
            this.buttonsList = this.buildButtonsList();

            if (!this.billingDocument.isInProgress() && this.billingDocument instanceof Invoice) {
                this.invoiceService.getPayments(this.billingDocument.id).subscribe(payments => {
                    this.payments = payments;
                });
            }

            if (this.isInvoice &&  this.billingDocument.id && !this.billingDocument.isInProgress()) {
                this.creditService.getLinkedCredits(this.billingDocument.id)
                    .subscribe(credits => this.credits = credits);
            }

            if (this.billingDocument.id) {
                this.billingDocumentService.getLinkedDocuments(this.billingDocument.id).subscribe( attachments => {
                    this.linkedDocuments = attachments;
                });
            }

            const customerAccountBuffer = history.state.customerAccountBuffer;
            if (this.billingDocument.metadata?.customerAccountId) {
                this.customerAccountService.getCustomerAccountById(this.billingDocument.metadata.customerAccountId)
                    .subscribe(customerAccount => {
                        this.customerAccount = this.fillCustomerAccountWithInvoiceCustomer(customerAccount);
                        if (customerAccount.chorusProActivated) {
                            this.findInvoiceCustomer(customerAccount)
                                .subscribe(customer => {
                                    if (!customer) {
                                        throw Error('[invoice-detail]: no correspondent customer found in invoice !!!');
                                    } else {
                                        this.chorusProSettings = customer.chorusProCustomerSettings;
                                    }
                                }, (err: HttpErrorResponse) => {
                                    throw Error('[invoice-detail] : ' + err.error);
                                });
                        }
                        if (customerAccount.accountManager) {
                            this.userService.getAccountUserByLogin(customerAccount.accountManager.login).subscribe(accountManager => {
                                this.customerAccountManager = accountManager;
                            });
                        }
                    });
            } else if (customerAccountBuffer) {
                // used only if user came here with customer account button
                this.refreshInvoiceCustomer(plainToClassFromExist(CustomerAccount.empty(), customerAccountBuffer));
            }
        });

        this.connectedUserService.getCurrentFullUser().subscribe(fullUser => {
            this.connectedUser = fullUser;
        });
    }

    fillCustomerAccountWithInvoiceCustomer(customerAccount: CustomerAccount): CustomerAccount {
        customerAccount.name = this.billingDocument.customer.name;
        customerAccount.accountingReference = this.billingDocument.metadata.customerAccountingReference;
        customerAccount.sirenNumber = this.billingDocument.customer.siren;
        customerAccount.siretNumber = this.billingDocument.customer.siret;
        customerAccount.vatRatio = this.billingDocument.metadata.vatRate;
        customerAccount.vatNumber = this.billingDocument.metadata.customerVatNumber;
        return customerAccount;
    }

    isSaveDisabled(): boolean {
        return this.generalInfoInvalid || this.customersInvalid || this.addressesInvalid
            || (!this.generalInfoDirty && !this.customersDirty && !this.addressesDirty && !this.linesDirty && !this.discountDirty);
    }

    canAcceptDocument(): boolean {
        return !this.generalInfoInvalid &&
            !this.customersInvalid &&
            !this.addressesInvalid &&
            (this.billingDocument.tokensArticlesLines.length > 0 || this.billingDocument.articlesLines.length > 0) &&
            (!this.billingDocument.isChorusProServiceMandatory(this.chorusProSettings) ||
                (this.billingDocument.customer.chorusProServiceCode != undefined && this.billingDocument.customer.chorusProServiceCode !== '')
            );
    }

    onListButtonClicked(buttonId: number): void {
        this.buttonsList[buttonId].action();
    }

    transformToAddressInvoice(baseAddress: Address): InvoiceAddressWithName {
        if (!baseAddress) {
            return new InvoiceAddressWithName(
                '',
                '',
                '',
                '',
                '',
                '');
        } else {
            return new InvoiceAddressWithName(
                baseAddress.name,
                baseAddress.line1,
                baseAddress.postalCode,
                baseAddress.city,
                baseAddress.country,
                baseAddress.line2
            );
        }
    }

    fillCustomerDataOnInvoice(customerAccount: CustomerAccount): void {
        const billingAddress = customerAccount.getContact(AddressType.BILLING)?.address;
        const deliveryAddress = customerAccount.getContact(AddressType.DELIVERY)?.address;

        this.billingDocument.customer.billingAddress = this.transformToAddressInvoice(billingAddress);
        this.billingDocument.customer.deliveryAddress = this.transformToAddressInvoice(deliveryAddress);

        this.billingDocument.metadata.customerAccountId = customerAccount.id;
        this.billingDocument.metadata.vatRate = customerAccount.vatRatio;
        this.billingDocument.metadata.customerAccountingReference = customerAccount.accountingReference;
        this.billingDocument.metadata.customerVatNumber = customerAccount.vatNumber;
    }

    fillInvoiceCustomerWithoutAddress(customer: Customer): void {
        this.billingDocument.customer.id = customer.id;
        this.billingDocument.customer.name = customer.name;
        this.billingDocument.customer.fullName = customer.fullName;
        this.billingDocument.customer.siret = customer.siret;
        this.billingDocument.customer.siren = customer.siren;
    }

    refreshInvoiceCustomer(customerAccount: CustomerAccount): void {
        this.fillCustomerDataOnInvoice(customerAccount);
        this.customerAccount = customerAccount;

        if (this.customerAccount.accountManager) {
            this.userService.getAccountUserByLogin(customerAccount.accountManager.login).subscribe(accountManager => {
                this.customerAccountManager = accountManager;
                this.billingDocument.metadata.customerAccountManagerLogin = accountManager.login;
            });
        }

        this.findInvoiceCustomer(customerAccount)
            .subscribe(customer => {
                if (!customer) {
                    throw Error('[invoice-detail]: no correspondent customer found in invoice !!!');
                } else {
                    this.chorusProSettings = customer.chorusProCustomerSettings;
                    this.fillInvoiceCustomerWithoutAddress(customer);
                }
            }, (err: HttpErrorResponse) => {
                throw Error('[invoice-detail] : ' + err.error);
            });
    }

    private findInvoiceCustomer(customerAccount: CustomerAccount): Observable<Customer> {
        return this.customerService.getCustomers(customerAccount.name)
            .pipe(map(customers => customers.find(el => el.name.trim() === customerAccount.name.trim())));
    }

    // line manipulation

    private editLine(lineEdit: LineEdit, linesToUpdate: InvoiceLine[]): void {
        linesToUpdate[lineEdit.index] = lineEdit.newLine;
        this.calculateNumbersWithDiscount();
    }

    editTokenArticleLine(newLine: LineEdit): void {
        this.editLine(newLine, this.billingDocument.tokensArticlesLines);
    }

    editArticleLine(newLine: LineEdit): void {
        this.editLine(newLine, this.billingDocument.articlesLines);
    }

    private addLine(invoiceLine: InvoiceLine, linesToUpdate: InvoiceLine[]): void {
        linesToUpdate.push(invoiceLine);
        this.calculateNumbersWithDiscount();
    }

    addTokenArticleLine(invoiceLine: InvoiceLine): void {
        invoiceLine.metadata.isTokenArticleLine = true;
        this.addLine(invoiceLine, this.billingDocument.tokensArticlesLines);
    }

    addArticleLine(invoiceLine: InvoiceLine): void {
        invoiceLine.metadata.isTokenArticleLine = false;
        this.addLine(invoiceLine, this.billingDocument.articlesLines);
    }

    private deleteLine(invoiceLineIndex: number, lines: InvoiceLine[]): void {
        lines.splice(invoiceLineIndex, 1);
        this.calculateNumbersWithDiscount();
    }

    deleteTokenArticleLine(invoiceLineId: number): void {
        this.deleteLine(invoiceLineId, this.billingDocument.tokensArticlesLines);
    }

    deleteArticleLine(invoiceLineId: number): void {
        this.deleteLine(invoiceLineId, this.billingDocument.articlesLines);
    }

    // billing metrics manipulation

    onDiscountPercentageChange(discountPercentage: number): void {
        this.discountDirty = true;
        this.billingDocument.billingDocumentAdditionalInformation.tokenDiscountPercentage = discountPercentage ? discountPercentage : 0;
        if (!this.billingDocument.trustValues) {
            this.applyDiscountForAll();
        }
    }

    // calculate the discount
    calculateNumbersWithDiscount(): void {
        if (!this.billingDocument.trustValues) {
            this.billingDocument.metadata.totalTokenAmount = InvoiceComputingUtils.getTotalTokens(this.billingDocument);
            // On credit notes, there is a need to keep the discount from the original invoice
            if (!this.isCredit) {
              this.billingDocument.billingDocumentAdditionalInformation.tokenDiscountPercentage =
                InvoiceComputingUtils.calculateDiscountPercentage(this.billingDocument.metadata.totalTokenAmount);
            }
            this.billingDocument.grossAmount.value = InvoiceComputingUtils.calculateTotalGross(this.billingDocument);
            this.applyDiscountForAll();
            this.billingDocument.billingDocumentAdditionalInformation.tokenGrossAmount = InvoiceComputingUtils.calculateTokenGrossTotal(
                this.billingDocument
            );
            this.billingDocument.billingDocumentAdditionalInformation.tokenGrossAmountAfterDiscount =
              parseFloat((
                this.billingDocument.billingDocumentAdditionalInformation.tokenGrossAmount -
                this.billingDocument.discountAmount.value
              ).toFixed(2));
        }
    }

    // take the first discount
    calculateNumbersWithoutDiscount(): void {
        if (!this.billingDocument.trustValues) {
            this.billingDocument.metadata.totalTokenAmount = InvoiceComputingUtils.getTotalTokens(this.billingDocument);
            const linesWithDiscount = this.billingDocument.tokensArticlesLines
                .filter(line => line.discount > 0);
            if (linesWithDiscount.length > 0) {
                this.billingDocument.billingDocumentAdditionalInformation.tokenDiscountPercentage = linesWithDiscount[0].discount;
            } else {
                this.billingDocument.billingDocumentAdditionalInformation.tokenDiscountPercentage = 0;
            }
            this.billingDocument.vatAmount.value = InvoiceComputingUtils.calculateTotalVat(this.billingDocument);
            this.billingDocument.netAmount.value = InvoiceComputingUtils.calculateTotalNet(this.billingDocument);
            this.billingDocument.grossAmount.value = InvoiceComputingUtils.calculateTotalGross(this.billingDocument);
            this.billingDocument.discountAmount.value = InvoiceComputingUtils.calculateTotalDiscount(this.billingDocument);
            this.billingDocument.billingDocumentAdditionalInformation.tokenGrossAmount =
              InvoiceComputingUtils.calculateTokenGrossTotal(this.billingDocument);
            this.billingDocument.billingDocumentAdditionalInformation.tokenGrossAmountAfterDiscount =
                parseFloat((
                  this.billingDocument.billingDocumentAdditionalInformation.tokenGrossAmount -
                  this.billingDocument.discountAmount.value
                ).toFixed(2));
        }
    }

    applyDiscountForAll(): void {
        if (!this.billingDocument.trustValues) {
            this.billingDocument.tokensArticlesLines.forEach(tokenArticleLine => {
                tokenArticleLine.discount = this.billingDocument.billingDocumentAdditionalInformation.tokenDiscountPercentage;
                tokenArticleLine.discountAmount.value = this.billingDocument.billingDocumentAdditionalInformation.tokenDiscountPercentage *
                    tokenArticleLine.grossAmount.value;
                this.recalculateNetAmountForLine(tokenArticleLine);
            });
            this.billingDocument.discountAmount.value = InvoiceComputingUtils.calculateTotalDiscount(this.billingDocument);
            this.billingDocument.grossAmountAfterDiscount.value = this.billingDocument.grossAmount.value - this.billingDocument.discountAmount.value;
            this.billingDocument.vatAmount.value = InvoiceComputingUtils.calculateTotalVat(this.billingDocument);
            this.billingDocument.netAmount.value = InvoiceComputingUtils.calculateTotalNet(this.billingDocument);
        }
    }

    recalculateNetAmountForLine(invoiceLine: InvoiceLine): void {
        if (!this.billingDocument.trustValues) {
            const grossAmountAfterDiscount = invoiceLine.grossAmount.value - invoiceLine.discountAmount.value;
            InvoiceDetailComponent.recomputeLineVatAmount(invoiceLine, this.billingDocument.metadata.vatRate);
            invoiceLine.netAmount.value = grossAmountAfterDiscount + invoiceLine.vatAmount.value;
        }
    }

    applyNetForAll(newVatPercentage: number): void {
        if (!this.billingDocument.trustValues) {
            this.billingDocument.metadata.vatRate = newVatPercentage;
            allLines(this.billingDocument).forEach(invoiceLine => {
                invoiceLine.vatRate.rate = newVatPercentage * 100;
                InvoiceDetailComponent.recomputeLineVatAmount(invoiceLine, newVatPercentage);
                invoiceLine.netAmount.value = invoiceLine.grossAmount.value - invoiceLine.discountAmount.value + invoiceLine.vatAmount.value;
            });
            this.calculateNumbersWithoutDiscount();
        }
    }

    onResetPercentageClick(): void {
        this.billingDocument.billingDocumentAdditionalInformation.tokenDiscountPercentage = InvoiceComputingUtils.calculateDiscountPercentage(
            this.billingDocument.metadata.totalTokenAmount
        );
        this.discountDirty = true;
        if (!this.billingDocument.trustValues) {
            this.applyDiscountForAll();
        }
    }

    // draft creation
    createInvoiceDraft(): void {
        this.createInvoiceDraftLoading = true;
        this.billingDocument.metadata.customerAccountId = this.customerAccount.id;
        this.invoiceService.createInvoiceDraft(this.billingDocument as ExtendedInvoice).subscribe(invoice => {
            this.createInvoiceDraftLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.billingDocument = ExtendedInvoice.empty();
            this.router.navigate(['boa', 'accounting', 'invoices', 'drafts', invoice.id]);
        }, err => {
            this.createInvoiceDraftLoading = false;
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    saveDraft(): void {
        this.saveInvoiceDraftLoading = true;
        this.invoiceService.updateInvoiceDraft(this.billingDocument as ExtendedInvoice).subscribe(invoiceDraft => {
            this.saveInvoiceDraftLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.reloadBillingDocument();
            this.markAllPristine();
        }, err => {
            this.saveInvoiceDraftLoading = false;
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    sendDraft(): void {
        this.sendInvoiceDraftLoading = true;
        this.invoiceService.updateInvoiceDraft(this.billingDocument as ExtendedInvoice).pipe(
            flatMap(_ => this.invoiceService.createFromDraft(this.billingDocument.id))
        ).subscribe(invoice => {
            this.sendInvoiceDraftLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.router.navigate(['boa', 'accounting', 'invoices', invoice.id]);
        }, err => {
            this.sendInvoiceDraftLoading = false;
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    openConfirmationPopup(): void {
        this.popup.open();
    }

    createQuoteDraft(): void {
        this.createQuoteDraftLoading = true;
        this.quoteService.createQuoteDraft(this.billingDocument as ExtendedQuote).subscribe(quote => {
            this.createQuoteDraftLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.billingDocument = ExtendedQuote.empty();
            this.router.navigate(['boa', 'accounting', 'quotes', 'drafts', quote.id]);
        }, err => {
            this.createQuoteDraftLoading = false;
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    saveQuoteDraft(): void {
        this.saveQuoteDraftLoading = true;
        this.quoteService.updateQuoteDraft(this.billingDocument as ExtendedQuote).subscribe(_ => {
            this.saveQuoteDraftLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.router.navigate([], {
                relativeTo: this.route
            });
            this.markAllPristine();
        }, err => {
            this.saveQuoteDraftLoading = false;
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    // quote specifics actions

    acceptQuote(): void {
        this.acceptQuoteLoading = true;
        this.quoteService.acceptFromDraft(this.billingDocument as ExtendedQuote).subscribe(invoice => {
            this.acceptQuoteLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.router.navigate(['boa', 'accounting', 'invoices', 'drafts', invoice.id]);
        }, err => {
            this.acceptQuoteLoading = false;
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    refuseQuote(): void {
        this.refuseQuoteLoading = true;
        this.quoteService.refuseFromDraft(this.billingDocument as ExtendedQuote).subscribe(quote => {
            this.refuseQuoteLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.navigationService.goToQuotePage(quote.id);
        }, err => {
            this.refuseQuoteLoading = false;
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    // form manipulation

    markAllPristine(): void {
        this.generalInfoDirty = false;
        this.customersDirty = false;
        this.linesDirty = false;
        this.addressesDirty = false;
        this.discountDirty = false;
    }

    refreshFileList(): void {
        this.billingDocumentService.getLinkedDocuments(this.billingDocument.id).subscribe(attachments => {
            this.linkedDocuments = attachments;
        });
    }

    // credit

    getDue(): number {
        const paid = this.payments.reduce<number>((acc, current) => {
            return current.status.value === PaymentStatusEnum.DONE
                ? acc + current.amount.value
                : acc;
        }, 0);
        const credit = this.credits.reduce<number>((acc, current) => {
            return current.grossAmount.value + acc;
        }, 0);

        return this.billingDocument.netAmount.value - paid - credit;
    }

    goToCreateCredit(): void {
        this.creditService.getNextCreditNoteReference().subscribe(creditNoteReference => {
            this.navigationService.goToCreditCreationPage(
                (this.billingDocument as Invoice).toCredit(creditNoteReference),
                this.getDue()
            );
        });
    }

    createCredit(): void {
        this.creditService.createCredit(this.billingDocument as Credit)
            .subscribe(createdCredit => {
                this.navigationService.goToInvoicePage(createdCredit.invoiceRef);
            });
    }

    // duplication

    duplicate(): void {
        this.navigationService.goToDuplicatePage(this.billingDocument.duplicate());
    }

    cancelQuote(): void {
        this.cancelQuoteLoading = true;
        this.quoteService.cancelFromDraft(this.billingDocument as Quote).subscribe(quote => {
            this.cancelQuoteLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.navigationService.goToQuotePage(quote.id);
        }, err => {
            this.cancelQuoteLoading = false;
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    canAccessToTokenTransfer(): boolean {
        if (!this.billingDocument.id) {
            return false;
        }

        if (this.billingDocument.getType() === BillingDocumentType.CREDIT) {
            return false;
        }

        return !((this.billingDocument) as ExtendedBillingDocument).isDelivered;
    }

    displayAccessToTokenTransfer(): void {
        this.displayTokenTransfer = true;
        interval(0.0000001)
            .pipe(take(1))
            .subscribe(() => {
                document.getElementById('tokenTransfer').
                scrollIntoView({behavior: 'smooth', block: 'end'});
            });
    }

    hideTokenTransferBlock(): void {
        this.displayTokenTransfer = false;
    }

    onTokenTransfer(): void {
        this.reloadBillingDocument();
        this.hideTokenTransferBlock();
    }

    private reloadBillingDocument() {
        let extendedBillingDocumentPromise: Observable<ExtendedBillingDocument>;
        const id = this.billingDocument.id;

        if (this.billingDocument.isInProgress()) {
            if (this.isQuote) {
                extendedBillingDocumentPromise = this.quoteService.getQuoteDraftById(id)
            } else {
                extendedBillingDocumentPromise = this.invoiceService.getInvoiceDraftById(id)
            }
        } else {
            if (this.isQuote) {
                extendedBillingDocumentPromise = this.quoteService.getQuoteById(id)
            } else {
                extendedBillingDocumentPromise = this.invoiceService.getById(id)
            }
        }

        extendedBillingDocumentPromise.subscribe(billingDocument => {
            this.billingDocument = billingDocument
            this.buttonsList = this.buildButtonsList();
        });
    }

    isMainButtonCreateQuote(): boolean {
        return this.isQuote && !this.billingDocument.id;
    }

    isMainButtonAcceptQuote(): boolean {
        return this.isQuote && (this.billingDocument as Quote).quoteStatus === QuoteStatus.IN_PROGRESS;
    }

    isMainButtonDuplicate(): boolean {
        if (this.isQuote) {
            const quoteStatus = (this.billingDocument as Quote).quoteStatus;

            return quoteStatus === QuoteStatus.VALIDATED ||
                quoteStatus === QuoteStatus.DENIED ||
                quoteStatus === QuoteStatus.CANCELED;
        }

        if (this.isInvoice) {
            const invoice = (this.billingDocument as ExtendedInvoice);
            const invoiceStatus = invoice.invoiceStatus;

            return invoice.isDelivered &&
                (invoiceStatus === InvoiceStatus.CUSTOMER_SENT ||
                    invoiceStatus === InvoiceStatus.PAID ||
                    invoiceStatus === InvoiceStatus.OVERDUE ||
                    invoiceStatus === InvoiceStatus.CHORUS_SENT ||
                    invoiceStatus === InvoiceStatus.PARTLY_PAID
                );
        }

        return false;
    }

    isMainButtonSendDraft(): boolean {
        return this.isInvoice && (this.billingDocument as Invoice).invoiceStatus === InvoiceStatus.IN_PROGRESS;
    }

    isMainButtonTokenTransfer(): boolean {
        if (!this.isInvoice) {
            return false;
        }

        const invoice = (this.billingDocument as ExtendedInvoice);
        const invoiceStatus = invoice.invoiceStatus;

        return (invoiceStatus === InvoiceStatus.PAID ||
                invoiceStatus === InvoiceStatus.CUSTOMER_SENT ||
                invoiceStatus === InvoiceStatus.OVERDUE ||
                invoiceStatus === InvoiceStatus.CHORUS_SENT ||
                invoiceStatus === InvoiceStatus.PARTLY_PAID)
            && !invoice.isDelivered;
    }

    isMainButtonCreateCredit(): boolean {
        return this.isCredit && !this.billingDocument.id;
    }

    isMainButtonCreateInvoiceDraft(): boolean {
        return this.isInvoice && !this.billingDocument.id;
    }

    isMainButtonResendChorusPro(): boolean {
        return this.billingDocument.getStatus() === InvoiceStatus.CHORUS_REFUSED;
    }

    isSplitButtonLoading(): boolean {
        return this.saveInvoiceDraftLoading ||
            this.sendInvoiceDraftLoading ||
            this.createQuoteDraftLoading ||
            this.saveQuoteDraftLoading ||
            this.acceptQuoteLoading ||
            this.refuseQuoteLoading ||
            this.cancelQuoteLoading ||
            this.resendToChorusProLoading;
    }

    isSplitButtonDisabled(): boolean {
        return (this.isMainButtonCreateCredit() && (this.netTotal > this.creditNotToExceed || this.netTotal === 0)) ||
            ((this.isMainButtonCreateQuote() ||
                this.isMainButtonCreateInvoiceDraft() ||
                this.isMainButtonAcceptQuote() ||
                this.isMainButtonSendDraft())
                && !this.canAcceptDocument());
    }

    onSplitButtonMainButtonClick(): void {
        if (this.isMainButtonAcceptQuote()) {
            this.openConfirmationPopup();
        } else if (this.isMainButtonDuplicate()) {
            this.duplicate();
        } else if (this.isMainButtonSendDraft()) {
            this.openConfirmationPopup();
        } else if (this.isMainButtonTokenTransfer()) {
            this.displayAccessToTokenTransfer();
        } else if (this.isMainButtonCreateCredit()) {
            this.openConfirmationPopup();
        } else if (this.isMainButtonCreateQuote()) {
            this.createQuoteDraft();
        } else if (this.isMainButtonCreateInvoiceDraft()) {
            this.createInvoiceDraft();
        } else if (this.isMainButtonResendChorusPro()) {
            this.resendInvoiceToChorusPro();
        }
    }

    private resendInvoiceToChorusPro(): void {
        this.resendToChorusProLoading = true;
        this.invoiceService.retrySendingToChorusPro(this.billingDocument.id).subscribe(
            billingDocument => {
                this.billingDocument = billingDocument;
                this.resendToChorusProLoading = false;
            },
          err => {
              this.toastrService.error(err.error);
              this.resendToChorusProLoading = false;
          }
        );
    }

    private buildButtonsList(): Array<{
        label: string;
        icon: string;
        action: () => void;
        cssClass: string;
    }> {
        const buttonsList = [];
        const errorButtonClass = '_error';
        const lowButtonClass = '_warning';

        if (this.billingDocument.id && (this.isQuote || this.isInvoice) && !this.isMainButtonDuplicate()) {
            buttonsList.push({
                label: 'invoice-detail.button.duplicate',
                icon: 'fa-copy',
                action: () => this.duplicate(),
                class: ''
            });
        }

        if (this.canAccessToTokenTransfer() && !this.isMainButtonTokenTransfer()) {
            buttonsList.push({
                label: 'invoice-detail.transfer.tokens',
                icon: 'fa-exchange-alt',
                action: () => this.displayAccessToTokenTransfer(),
                cssClass: ''
            });
        }

        if (this.isInvoice && this.billingDocument.id) {
            const invoice = this.billingDocument as Invoice;
            const invoiceStatus = invoice.invoiceStatus;

            if ((invoiceStatus === InvoiceStatus.CUSTOMER_SENT ||
              invoiceStatus === InvoiceStatus.PARTLY_PAID ||
              invoiceStatus === InvoiceStatus.OVERDUE)
              && this.getDue() !== 0) {
                buttonsList.push({
                    label: 'invoice-detail.credit.create',
                    icon: 'fa-paper-plane',
                    action: () => this.goToCreateCredit(),
                    cssClass: ''
                });
            }
        }

        if (this.isQuote && this.billingDocument.id) {
            const quoteStatus = (this.billingDocument as Quote).quoteStatus;

            if (quoteStatus === QuoteStatus.IN_PROGRESS) {
                buttonsList.push({
                    label: 'invoice-detail.quote.draft.refuse',
                    icon: 'fa-times-circle',
                    action: () => this.refuseQuote(),
                    cssClass: lowButtonClass
                });

                buttonsList.push({
                    label: 'invoice-detail.quote.draft.cancel',
                    icon: 'fa-trash-alt',
                    action: () => this.cancelQuote(),
                    cssClass: errorButtonClass
                });
            }
        }

        return buttonsList;
    }
}
