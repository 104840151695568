<div>
    <form #form='ngForm'>
        <div class="grid-x grid-margin-x">
            <a-input class="cell phone-12 desktop-24"
                     [label]="'N° Document'"
                     [disabled]="true"
                     [required]="true"
                     [tabindex]="3"
                     [ngModel]="reference"
                     name="reference">
            </a-input>
            <a-input class="cell phone-12 desktop-24"
                     [label]="'N° Bon Commande'"
                     [disabled]="!isInProgress"
                     [(ngModel)]="billingDocument.metadata.purchaseOrderNumber"
                     [tabindex]="3"
                     name="purchase-order-number">
            </a-input>
        </div>
        <spacer size="16"></spacer>
        <div class="grid-x grid-margin-x">
            <a-date-picker
                [(ngModel)]="billingDocument.date"
                [required]="true"
                (ngModelChange)="refreshDueDate($event)"
                [disabled]="!isInProgress"
                [label]=" isInvoice ? ('invoice.date.label' | translate) : ('quote.date.label' | translate)"
                class="cell phone-12"
                name="date"></a-date-picker>
            <a-date-picker *ngIf="isInvoice"
                           [(ngModel)]="$any(billingDocument).dueDate"
                           [required]="true"
                           [disabled]="!isInProgress"
                           [label]="'Date d\'échéance'"
                           class="cell phone-12"
                           name="dueDate"></a-date-picker>
        </div>
    </form>

    <spacer size="24"></spacer>
    <div class="grid-x grid-margin-x grid-margin-y align-center">
        <ng-template [ngIf]="linkedDocuments?.length > 0 && !isCredit">
            <m-preview-button
                *ngFor="let quote of linkedDocuments"
                [blobSrc]="quote.getPdfLink()"
                [fileName]="quote.getFileName()"
                class="cell phone-12 big-desktop-24 invoiceButton">Aperçu devis lié N°{{quote.reference}}
            </m-preview-button>
            <a-button-default [routerLink]="['/boa', 'accounting', 'quotes', quote.id]"
                              class="cell phone-12 big-desktop-24 invoiceButton" *ngFor="let quote of linkedDocuments">
                <i class="far fa-eye"></i>Devis Lié N°{{quote.reference}}
            </a-button-default>
        </ng-template>
        <ng-template [ngIf]="linkedInvoice">
            <m-preview-button
                [blobSrc]="linkedInvoice.getPdfLink()"
                [fileName]="linkedInvoice.getFileName()"
                class="cell phone-12 big-desktop-24 invoiceButton">Aperçu facture liée N°{{linkedInvoice.reference}}
            </m-preview-button>
            <a-button-default [routerLink]="['/boa', 'accounting', 'invoices', linkedInvoice.id]"
                              class="cell phone-12 big-desktop-24 invoiceButton">
                <i class="far fa-eye"></i>Facture Liée N°{{linkedInvoice.reference}}
            </a-button-default>
        </ng-template>
    </div>
</div>
