import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {BillingDocument, BillingDocumentType, ListBillingDocument} from '../models/invoices/billing-document';
import {BillingDocumentService} from '../services/billing-document.service';
import {Result} from '../../common/models/shared/result';
import {QuoteStatus} from '../models/invoices/quote';
import {InvoiceStatus} from '../models/invoices/invoice';

@Injectable()
export class BillingDocumentsResolver implements Resolve<Result<ListBillingDocument>> {
    constructor(private billingDocumentService: BillingDocumentService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Result<ListBillingDocument>> {
        const types = route.queryParamMap.getAll('billingTypes');
        return this.billingDocumentService.findPaginated(route.queryParamMap.get('search'),
            route.queryParamMap.get('fromDate')
                ? new Date(route.queryParamMap.get('fromDate'))
                : null,
            route.queryParamMap.get('toDate')
                ? new Date(route.queryParamMap.get('toDate'))
                : null,
            types.map(el => {
                if (el === 'ALL') {
                    return 'ALL';
                } else {
                    return BillingDocumentType[el];
                }
            }),
            types.length === 1
                ? route.queryParamMap.getAll('billingStatus').map(el => {
                    if (el === 'ALL') {
                        return 'ALL';
                    } else if (types[0] === BillingDocumentType.QUOTE.toString()) {
                        return QuoteStatus[el];
                    } else {
                        return InvoiceStatus[el];
                    }
                })
                : null,
            30,
            0,
            route.queryParamMap.get('sort'),
            route.queryParamMap.get('customerAccountId'),
            route.queryParamMap.get('isDelivered') ? route.queryParamMap.get('isDelivered') === 'true' : null
        );
    }
}
