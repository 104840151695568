<button class="splitButton-button"
        [class._loading]="loading"
        [class]="'button ' + fromSize(size) + ' ' + fromVisibility(visibility) + ' ' + fromRole(role)" type="button"
        [class._disabled]="disabled" [class._validate]="validate" (click)="doAction($event)">
    <i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
    <ng-content></ng-content>
</button>

<div (click)="toggleListOpened()"
        id="showMoreOptions"
        [class._open]="listOpened" class="splitButton-arrow">
    <i class="fas fa-chevron-down"></i>
</div>

<div class="splitButton-list" [class._open]="listOpened">
    <button *ngFor="let button of buttonsList; let index = index"
            [class]="'splitButton-list-item ' + button.cssClass"
            (click)="onListButtonClicked(index)"
            [disabled]="button.isDisabledFunction && button.isDisabledFunction()">
            <span class="splitButton-list-item-icon">
                <i class="fas {{button.icon}}"></i>
            </span>
        {{button.label | translate}}
    </button>
</div>
