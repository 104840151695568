<div class="cell shrink grid-x align-justify">
    <a-button-back class="cell shrink">{{'common.action.back' | translate}}</a-button-back>

    <a-button-default class="cell shrink" *ngIf="customerAccount?.id && !customerAccount?.locked" (click)="lockCustomer(true)">
        <i class="fas fa-lock"></i> {{'common.action.block' | translate}}
    </a-button-default>
    <a-button-default role="ERROR" class="cell shrink" *ngIf="customerAccount?.id && customerAccount?.locked" (click)="lockCustomer(false)">
        <i class="fas fa-unlock"></i> {{'common.action.unblock' | translate}}
    </a-button-default>
</div>

<spacer [size]="16"></spacer>

<div class="cell auto">
    <div class="grid-y grid-margin-x grid-margin-y">
        <div class="cell shrink">
            <div class="grid-x grid-margin-x grid-margin-y">
                <o-customer-account-information class="cell phone-24 desktop-15"
                                                [class.desktop-24]="customerInfoEditMode || creationMode"
                                                [creationMode]="creationMode"
                                                (editModeLaunched)="onEditModeLaunched()"
                                                (editModeQuit)="onEditModeQuit()"
                                                [customerAccount]="customerAccount">
                </o-customer-account-information>
                <div class="cell phone-24 desktop-9 grid-y" [class.desktop-24]="customerInfoEditMode">
                    <o-user-manager-information [customerAccount]="customerAccount"
                                                *ngIf="!creationMode"
                                                (transferClicked)="onManagerTransferClicked()"
                                                [manager]="customerAccount.accountManager"
                                                class="cell auto">
                    </o-user-manager-information>
                    <spacer size="16"></spacer>
                    <o-last-demonstration-animator class="cell shrink"
                                                   *ngIf="!creationMode"
                                                   [lastAnimator]="lastAnimatorDemo"
                                                   [customerAccountRef]="this.customerAccount.id"></o-last-demonstration-animator>
                </div>
            </div>
        </div>
        <!-- does not appear on desktop -->
        <div class="cell shrink show-for-phone hide-for-big-desktop" *ngIf="!creationMode">
            <div class="grid-x grid-margin-x grid-margin-y">
                <div class="cell phone-24">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <o-animators-list (transferClicked)="onAnimatorTransferClicked($event)"
                                          [customerAccountId]="customerAccount.id"
                                          class="cell oTokenTransferRight"
                                          [ngClass]="showTransferWindow ? 'phone-24 desktop-12' : 'phone-24'">
                        </o-animators-list>
                        <div class="oTokenTransfer"
                             [ngClass]="showTransferWindow ? 'cell phone-24 desktop-12 _show' : '_hide'">
                            <o-token-transfer
                                *ngIf="showTransferWindow"
                                [(initialOrigin)]="originUserBuffer"
                                [(initialTarget)]="targetUserBuffer"
                                (operationCanceled)="onTransferCanceled()"
                                (operationSent)="onTransferSent()">
                            </o-token-transfer>
                        </div>
                    </div>
                </div>
                <o-customer-last-operations
                    [verticalLayout]="true"
                    [customerAccountId]="customerAccount.id"
                    class="cell phone-12 medium-desktop-8"></o-customer-last-operations>
                <o-last-consumptions
                    [customerAccountId]="customerAccount.id"
                    [verticalLayout]="true"
                    class="cell phone-12 medium-desktop-8"></o-last-consumptions>
                <o-invoice-information class="cell phone-24 medium-desktop-8"
                                       [customerAccount]="customerAccount">
                </o-invoice-information>
            </div>
        </div>
        <!-- does not appear on phone -->
        <div class="cell shrink show-for-big-desktop hide-for-phone" *ngIf="!creationMode">
            <div class="grid-x grid-margin-x grid-margin-y">
                <div class="cell phone-24 desktop-15">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <o-animators-list [verticalLayout]="true"
                                          (transferClicked)="onAnimatorTransferClicked($event)"
                                          [customerAccountId]="customerAccount.id"
                                          class="cell phone-8 oTokenTransferRight">
                        </o-animators-list>
                        <div class="oTokenTransfer"
                             [ngClass]="showTransferWindow ? 'cell phone-16 _show' : '_hide'">
                            <o-token-transfer
                                *ngIf="showTransferWindow"
                                [(initialOrigin)]="originUserBuffer"
                                [(initialTarget)]="targetUserBuffer"
                                (operationCanceled)="onTransferCanceled()"
                                (operationSent)="onTransferSent()">
                            </o-token-transfer>
                        </div>
                        <o-customer-last-operations
                            *ngIf="!showTransferWindow"
                            [verticalLayout]="true"
                            [customerAccountId]="customerAccount.id"
                            [ngClass]="showTransferWindow ? '_hide' : '_show'"
                            class="cell phone-8"></o-customer-last-operations>
                        <o-last-consumptions
                            *ngIf="!showTransferWindow"
                            [customerAccountId]="customerAccount.id"
                            [verticalLayout]="true"
                            [ngClass]="showTransferWindow ? '_hide' : '_show'"
                            class="cell phone-8"></o-last-consumptions>
                    </div>
                </div>
                <o-invoice-information class="cell phone-24 desktop-9"
                                       [customerAccount]="customerAccount">
                </o-invoice-information>
            </div>
        </div>
    </div>
    <spacer [size]="24"></spacer>
</div>


