import {Component, Input} from '@angular/core';
import {ListBillingDocument} from '../../../../newboa/accounting/models/invoices/billing-document';
import {NavigationService} from '../../../../newboa/common/services/navigation.service';

@Component({
    selector: 'm-invoices-list',
    templateUrl: './m-invoices-list.component.html',
    styleUrls: ['./m-invoices-list.component.scss'],
    host: {class: 'grid-x grid-margin-x grid-margin-y'}
})
export class MInvoicesListComponent {

    @Input()
    billingDocuments: Array<ListBillingDocument>;

    constructor(public navigationService: NavigationService) {
    }
}
