<o-block [headerLabel]="'o-transfer-token.header' | translate">
    <ng-container button>
        <a-dashboard-button-icon (click)="switchFreeMode()" class="cell shrink" [visibility]="'LOW'">
            <i [class]="!freeMode ? 'fas fa-lock' : 'fas fa-lock-open'"></i>
        </a-dashboard-button-icon>
    </ng-container>
    <form [formGroup]="operationForm" (ngSubmit)="send()">
        <div class="grid-x grid-margin-x">
            <a-input [formControlName]="'operationLabel'"
                     [required]="true"
                     [label]="'o-token-transfer.operation.label' | translate"
                     class="cell phone-12"></a-input>

            <a-select *ngIf="billingDocuments"
                [hidden]="!isArkheInvolved && !freeMode"
                [formControlName]="'selectedBillingDocument'"
                [clearable]="true"
                [required]="(
                    operationForm?.controls.originUser.value.role === role.ARKHE
                    || operationForm?.controls.targetUser.value.role === role.ARKHE
                ) && !freeMode"
                [itemList]="billingDocuments"
                bindValue="value"
                [label]="'o-token-transfer.operation.linkedDocument' | translate"
                [compareWith]="billingDocumentComparisonFunction"
                class="cell phone-12"></a-select>
        </div>

        <spacer [size]="24"></spacer>

        <div class="transferArea">
            <div class="grid-x align-center align-stretch">
                <div class="cell auto transferArea-item _sender">
                    <m-token-user class="cell shrink"
                                  [formControlName]="'originUser'"
                                  [type]="'emitter'"
                                  [freeMode]="freeMode"
                                  [userSelect]="customerUsersOrigin"></m-token-user>
                    <hr>

                    <div class="cell shrink grid-y">
                        <span class="cell shrink transferLabel">
                            {{switchClass ? ('o-token-transfer.recovery' | translate) : ('o-token-transfer.transfer' | translate)}}
                        </span>
                        <spacer size="8"></spacer>
                        <a-token-input class="cell shrink"
                                       [formControlName]="'amount'"
                                       [min]="1"
                                       [inputType]="'number'"></a-token-input>
                        <span class="help-text _error" [class._hidden]="!operationForm.controls.amount.errors?.max">{{'error.boa-max' | translate : {value: originTokens} }} </span>
                    </div>
                </div>

                <div class="cell shrink grid-y align-center align-middle">
                    <div class="cell shrink transferArea-icon"
                         [class._switch]="switchClass"
                         (click)="switchOperationDirection()">
                        <i class="fas fa-long-arrow-alt-right"></i>
                    </div>
                </div>

                <div class="cell auto">
                    <div class="transferArea-item" *ngIf="targetDisplayState === 'DISPLAY_TOKEN_USER'">
                        <m-token-user [formControlName]="'targetUser'"
                                      [freeMode]="freeMode"
                                      [type]="'receiver'"
                                      [userSelect]="customerUsersTarget"></m-token-user>
                        <hr>

                        <a-field [label]="'Stock initial'">
                            <ng-container value>
                                {{initialTarget.tokenBalance | number}} {{'common.token' | translate}}
                            </ng-container>
                        </a-field>
                        <spacer size="8"></spacer>
                        <m-user-info-token-balance class="_small" [label]="'Stock final'"
                                                   [ngClass]="'_' + initialTarget.role?.toLowerCase()"
                                                   [tokenBalance]="+initialTarget.tokenBalance + ( switchClass ?
                                                   - operationForm.controls.amount.value
                                                   : + operationForm.controls.amount.value)">
                        </m-user-info-token-balance>
                    </div>

                    <div class="transferArea-item _noUserMessage" *ngIf="targetDisplayState === 'DISPLAY_NO_USERS_MESSAGE'">
                        <div>
                        {{'o-transfer-token.customer-account-does-not-exist.1' | translate}}
                        <a-customer-link [customerAccount]="customerAccount"></a-customer-link>
                        {{'o-transfer-token.customer-account-does-not-exist.2' | translate}}
                        </div>
                        <a-button-default class="transferArea-item-action" [visibility]="'HIGH'" [routerLink]="['/boa/customers', customerAccount?.id]">
                            {{'o-transfer-token.customer-account-does-not-exist.create' | translate}}
                        </a-button-default>
                    </div>
                </div>
            </div>
        </div>

        <spacer [size]="32"></spacer>

        <div class="grid-x align-justify align-middle">
            <a-checkbox class="cell shrink" [size]="'small'" name="isGift"
                        [label]="'o-transfer-token.gift' | translate"
                        [formControlName]="'isGift'"
                        *ngIf="freeMode"></a-checkbox>
            <a-checkbox class="cell shrink" [size]="'small'" name="mailNotification"
                        [label]="'notification.mail' | translate"
                        [formControlName]="'sendMailNotification'"></a-checkbox>
            <div class="cell shrink grid-x align-right grid-margin-x">
                <a-button-cancel class="cell shrink"
                                 (click)="cancel()">{{'common.action.cancel' | translate}}</a-button-cancel>
                <a-button-submit class="cell shrink"
                                 [loading]="transferLoading"
                                 [disabled]="operationForm.invalid || customerUsersTarget.length === 0">{{'common.validate' | translate}}</a-button-submit>
            </div>
        </div>
    </form>
</o-block>
