<div class="invoice-icon" m-icon [type]="billingDocument?.documentType"></div>

<span class="invoice-number" [ngClass]="'_' + billingDocument?.documentType.toLowerCase()">N°{{billingDocument.reference}}</span>
<span class="invoice-customer">{{billingDocument.customerName}}</span>
<span class="invoice-amount"><b>{{billingDocument.netAmount.value | currency : billingDocument.netAmount.currency}} {{'common.netAmount' | translate}}</b></span>

<div class="invoiceStatus-container">
    <span class="invoice-status" [ngClass]="'_' + status.toLowerCase()">
        {{'billings.status.' + billingDocument.documentType + '.' + status | translate}}
    </span>
    <span class="billing-status" *ngIf="billingDocument?.isDelivered">
        {{'billings.delivery.status.' + billingDocument.documentType +  '.true' | translate}}
    </span>
    <span class="billing-status _undelivered" *ngIf="!billingDocument?.isDelivered && billingDocument.documentType !== 'CREDIT'">
        {{'billings.delivery.status.' + billingDocument.documentType +  '.false' | translate}}
    </span>
</div>
