import {Customer} from './customer';
import {Vendor} from './vendor';
import {InvoiceLine} from './invoice-line';
import {CurrencyEnum} from './currency-enum';
import {Invoice, InvoiceStatus} from './invoice';
import {QuoteStatus} from './quote';
import {Amount} from './amount';
import {ChorusProCustomerSettings} from '../../../customers/models/chorus-pro-settings';

export class BillingDocumentMetadata {
    customerAccountId: string;
    linkedQuotesId: Array<string>;
    purchaseOrderNumber: string;
    vatRate: number;
    lastModificationDate: Date;
    linkedCreditsId: Array<string>;
    customerAccountingReference: string;
    customerVatNumber: string;
    customerAccountManagerLogin: string;
    totalTokenAmount: number;

    constructor(customerAccountId: string,
                linkedQuotesId: Array<string>,
                purchaseOrderNumber: string,
                vatRate: number,
                linkedCreditsId: Array<string>,
                lastModificationDate: Date,
                customerAccountManagerLogin: string,
                totalTokenAmount: number
    ) {
        this.customerAccountId = customerAccountId;
        this.linkedQuotesId = linkedQuotesId;
        this.linkedCreditsId = linkedCreditsId;
        this.purchaseOrderNumber = purchaseOrderNumber;
        this.vatRate = vatRate;
        this.lastModificationDate = lastModificationDate;
        this.customerAccountManagerLogin = customerAccountManagerLogin;
        this.totalTokenAmount = totalTokenAmount;
    }

    static empty(): BillingDocumentMetadata {
        return new BillingDocumentMetadata(
            undefined,
            [],
            undefined,
            undefined,
            [],
            new Date(),
            undefined,
            0
        );
    }
}

export enum BillingDocumentType {
    INVOICE = 'INVOICE',
    QUOTE = 'QUOTE',
    CREDIT = 'CREDIT'
}

export interface BillingDocument {
    id: string;
    reference: string;
    date: Date;
    customer: Customer;
    vendor: Vendor;
    subject: string;
    tokensArticlesLines: Array<InvoiceLine>;
    articlesLines: Array<InvoiceLine>;
    vatsAmount: Array<Amount>;
    grossAmount: Amount;
    netAmount: Amount;
    vatAmount: Amount;
    discountAmount: Amount;
    metadata: BillingDocumentMetadata;
    detail: string;
    legalNotice: string;
    withVAT: boolean;
    currency: CurrencyEnum;
    showLegalNoticeForeignCustomer: boolean;
    billingDocumentType: BillingDocumentType;
    grossAmountAfterDiscount: Amount;
    trustValues: boolean;
    billingDocumentAdditionalInformation: BillingDocumentAdditionalInformation;
    chorusProSendFailError: string;

    isInProgress(): boolean;

    isStrictlyInProgress(): boolean;

    getStatus(): QuoteStatus | InvoiceStatus;

    getPdfLink(): string;

    getFileName(): string;

    getType(): BillingDocumentType;

    getReference(): string;

    getDetailLink(): string;

    duplicate(): BillingDocument;

    isChorusProServiceMandatory(chorusProSettings: ChorusProCustomerSettings): boolean;
}

export function buildFileNameSuffix(billingDocument: BillingDocument): string {
    const month = billingDocument.date.getMonth() + 1;
    return billingDocument.customer.name + ' ' + billingDocument.date.getFullYear() + '-' + (
        (month > 9) ? month.toString() : '0' + month);
}

export function allLines(billingDocument: BillingDocument): InvoiceLine[] {
    return billingDocument.tokensArticlesLines.concat(billingDocument.articlesLines);
}

export function areLinesReadonly(billingDocument: BillingDocument): boolean {
    return !billingDocument.isInProgress() && (billingDocument.getType() !== BillingDocumentType.CREDIT || billingDocument.id !== null);
}

export interface ExtendedBillingDocument extends BillingDocument {
    deliveredTokens: number;
    toDeliverTokens: number;
    isDelivered: boolean;
}

export class BillingDocumentAdditionalInformation {
  private constructor(
    public additionalCustomerAccountsForTokenDelivery: CustomerAccountForTokenDelivery[],
    public deliverTokenToOtherCustomers: boolean,
    public tokenGrossAmountAfterDiscount: number,
    public tokenGrossAmount: number,
    public tokenDiscountPercentage: number
  ) {
    this.additionalCustomerAccountsForTokenDelivery = additionalCustomerAccountsForTokenDelivery;
  }

  static empty(): BillingDocumentAdditionalInformation {
    return new BillingDocumentAdditionalInformation(
      [],
      false,
      0,
      0,
      0
    );
  }

  isEmpty(): boolean {
    return this.additionalCustomerAccountsForTokenDelivery.length === 0;
  }
}

export class CustomerAccountForTokenDelivery {
  id: string;
  name: string;
  accountingReference: string;
}

export enum CreditNoteStatus {
    'VALIDATED' = 'VALIDATED'
}

export class ListBillingDocument {
    id: string;
    documentType: BillingDocumentType;
    draft: boolean;
    reference: string;

    customerName: string;
    customerAccountId: string;
    date: Date;
    grossAmountAfterDiscount: Amount;
    netAmount: Amount;
    isDelivered: boolean;

    invoiceStatus: InvoiceStatus;
    acceptedStatus: QuoteStatus;
    creditNoteStatus: CreditNoteStatus;

    getStatus(): string {
        switch(this.documentType) {
            case BillingDocumentType.INVOICE:
                return this.invoiceStatus.toString();
            case BillingDocumentType.QUOTE:
                return this.acceptedStatus.toString();
            case BillingDocumentType.CREDIT:
                return this.creditNoteStatus.toString();
        }
    }
}

export class ToBeDeliveredBillingDocument {
    id: string;
    reference: string;
    customerName: string;
    customerAccountId: string;
    grossAmountAfterDiscount: Amount;
    netAmount: Amount;
    purchaseOrderNumber: string;
    toDeliverTokens: number;
    documentType: BillingDocumentType;
    draft: boolean;

    static fromExtended(billingDocument: ExtendedBillingDocument): ToBeDeliveredBillingDocument {
        return {
            id: billingDocument.id,
            reference: billingDocument.reference,
            customerName: billingDocument.customer.name,
            customerAccountId: billingDocument.metadata.customerAccountId,
            grossAmountAfterDiscount: billingDocument.grossAmountAfterDiscount,
            netAmount: billingDocument.netAmount,
            purchaseOrderNumber: billingDocument.metadata.purchaseOrderNumber,
            toDeliverTokens: billingDocument.toDeliverTokens,
            documentType: billingDocument.getType(),
            draft: billingDocument.isInProgress()
        }
    }
}
