import {Component, Input, OnChanges} from '@angular/core';
import {
    BillingDocument, BillingDocumentType,
    ExtendedBillingDocument,
    ListBillingDocument
} from '../../../../newboa/accounting/models/invoices/billing-document';

@Component({
    selector: 'a-invoice',
    templateUrl: './a-invoice.component.html',
    styleUrls: ['./a-invoice.component.scss']
})
export class AInvoiceComponent {
    get billingDocument(): ListBillingDocument {
        return this._billingDocument;
    }

    @Input()
    set billingDocument(value: ListBillingDocument) {
        this._billingDocument = value;
        this.status = value.getStatus();
    }

    private _billingDocument: ListBillingDocument;

    status = '';

    constructor() { }
}
